import { Component } from "react";
import "./form.css";
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import Footer from "../modules/footer/footer.component";
import { NavBar } from '../modules/navBar/navBar.component'
import { Substation } from './substations';
import { Line } from './lines';
import { Point } from './points';
import { Historic } from './historic';
class Reports extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: 'line'
        }

    }
    render() {
        return (
            <div>
                <NavBar />
                <Tabs fill onSelect={(k) => this.setState({
                    activeTab: k
                })}>
                    <Tab eventKey="line" title="Línea de Transmisión">
                        <Line />
                    </Tab>
                    <Tab eventKey="substation" title="Subestaciones" >
                        <Substation />
                    </Tab>
                    <Tab eventKey="points" title="Coordenadas Puntuales" >
                        <Point />
                    </Tab>
                    <Tab eventKey="historic" title="Reporte histórico" >
                        <Historic />
                    </Tab>
                </Tabs>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedReports = connect(mapStateToProps)(Reports);
export { connectedReports as Reports };