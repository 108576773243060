import { authHeader, config } from '../_helpers';
import axios from "axios";

export const reportsService = {
    reportEvent,
    substationEvent,
    pointEvent,
    historicEvent
};

function reportEvent(event:any) {
    return axios.post(`${config.backend_url}/reports`,
    event, {headers: authHeader()})
    .then((data)=> {console.log("SUCCESS",data);return Promise.resolve(data)})
    .catch((err) => {console.log("ERROR");return Promise.reject(err)})
}

function substationEvent(event: any) {
    return axios.post(`${config.backend_url}/substations`,
        event, { headers: authHeader() })
        .then((data) => { console.log("SUCCESS", data); return Promise.resolve(data) })
        .catch((err) => { console.log("ERROR"); return Promise.reject(err) })
}

function pointEvent(event: any) {
    return axios.post(`${config.backend_url}/points`,
        event, { headers: authHeader() })
        .then((data) => { console.log("SUCCESS", data); return Promise.resolve(data) })
        .catch((err) => { console.log("ERROR"); return Promise.reject(err) })
}

function historicEvent(event: any) {
    return axios.post(`${config.backend_url}/historic-reports`,
        event, { headers: authHeader() })
        .then((data) => { console.log("SUCCESS", data); return Promise.resolve(data) })
        .catch((err) => { console.log("ERROR"); return Promise.reject(err) })
}