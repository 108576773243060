import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { reportsActions } from "../../_actions";
import PauseButton from "./pauseButton";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class Point extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: props.state.authentication.user.username, //username
      agent: "ITCO", // SLUG
      latitude: undefined,
      longitude: undefined,
      datetime: undefined,
      email_list: undefined,
      timestampAdvertisement: "",
      invalidTimestamp: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    if (name === "datetime") {
      let now = new Date();
      let timestamp = new Date(value);

      if (now.getTime() - timestamp.getTime() < 1000 * 60 * 5) {
        this.setState({
          timestampAdvertisement:
            "Consolidación de datos en proceso. Espere un momento e intente nuevamente.",
          invalidTimestamp: true,
        });
      } else {
        this.setState({ timestampAdvertisement: "", invalidTimestamp: false });
      }
    }
  }

  async handleSubmit() {
    const { dispatch } = this.props;
    const { timestampAdvertisiment, invalidTimestamp, ...data } = this.state;
    dispatch(reportsActions.pointEvent(data));
    await sleep(10000);
    console.log(this.state);
  }

  render() {
    return (
      <Container
        className="Form"
        fluid
        style={{ minHeight: "89.6vh", paddingTop: 40 }}
      >
        <Row>
          <h3>Formulario de solicitud de reportes de correlación</h3>
        </Row>
        <Row>
          <h6>
            Llene por favor los campos presentados a continuación, para generar
            automaticamente un reporte de correlación que será enviado a su
            correo.
          </h6>
        </Row>
        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-lg-3 col-sm-12">
            <label>Longitud</label>
          </Col>
          <Col className="col-lg-3 col-sm-15">
            <input
              required
              multiple
              className="form-control"
              type="float"
              name="longitude"
              id="longitude"
              value={this.state.longitude}
              onChange={this.handleInputChange}
              placeholder="Longitud en grados (decimal con punto '.')"
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-lg-3 col-sm-12">
            <label>Latitud</label>
          </Col>
          <Col className="col-lg-3 col-sm-15">
            <input
              required
              multiple
              className="form-control"
              type="float"
              name="latitude"
              id="latitude"
              value={this.state.latitude}
              onChange={this.handleInputChange}
              placeholder="Latitud en grados (decimal con punto '.')"
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-3">
            <label>Fecha del evento</label>
          </Col>
          <Col className="col-sm-4">
            <input
              type="datetime-local"
              min="2020-07-10"
              className="form-control"
              required
              id="datetime"
              name="datetime"
              step=".001"
              value={this.state.datetime}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        {this.state.invalidTimestamp ? (
          <Row
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            className="row pb-3 px-5"
          >
            <Col className="col-lg-12 col-sm-12">
              <label
                style={{ color: "#cc3300", fontWeight: "bold" }}
              >{`${this.state.timestampAdvertisement}`}</label>
            </Col>
          </Row>
        ) : null}
        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5"
        >
          <Col className="col-lg-3 col-sm-12">
            <label>Correo electrónico</label>
          </Col>
          <Col className="col-lg-9 col-sm-12">
            <input
              required
              multiple
              className="form-control"
              type="email"
              name="email_list"
              id="email_list"
              value={this.state.email_list}
              onChange={this.handleInputChange}
              placeholder="Enviar reporte a correo electrónico"
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>Puede ingresar varios correos, separados por coma.</label>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} className="text-center pb-3">
          <PauseButton
            style={{ width: "200px" }}
            className="btn btn-lg btn-primary "
            type="submit"
            name="submit"
            disabled={
              !(
                !this.state.invalidTimestamp &&
                this.state.datetime &&
                this.state.longitude &&
                this.state.latitude &&
                this.state.email_list
              )
            }
            onClick={this.handleSubmit}
          >
            Enviar solicitud
          </PauseButton>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    state,
  };
};

const connectedPoint = connect(mapStateToProps)(Point);
export { connectedPoint as Point };
