import { Dispatch } from "redux";
import { grafanaService } from '../_services';


export const grafanaActions = {
    login
};

function login(username: string, password: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(username))

        grafanaService.login(username, password)
            .then((user: any) => {
                dispatch(success(user));
            }).catch((error: any) => {
                dispatch(failure(error));
            })
    }
    function request(user: any) { return { type: "GRAFANA_LOGIN_REQUEST", user } }
    function success(user: any) { return { type: "GRAFANA_LOGIN_SUCCESS", user } }
    function failure(error: any) { return { type: "GRAFANA_LOGIN_FAILED", error } }
}