import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

class Footer extends Component<any> {
  render() {
    return (
      <Container
        fluid
        className="bg-dark"
        style={{ paddingTop: 20, paddingBottom: 20 }}
      >
        <div className="text-center text-white">
          <hr
            style={{
              border: 0,
              borderTop: "1px solid rgba(255, 255, 255, 0.5)",
            }}
          />
          <Row style={{ paddingTop: "0px", marginTop: 0 }}>
            <Col xs={7}>
              <img alt="powerframes text" src="white.png" width="50px" />
            </Col>

            <Col xs={2}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "2rem",
                marginLeft: 0,
              }}
            >
              <Row>
                <a
                  href="https://www.linkedin.com/company/power-frames/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="fa-2x social-icon"
                    icon={faLinkedinIn}
                    style={{ marginLeft: "10px" }}
                  />
                </a>

                <a
                  href="https://www.instagram.com/powerframes.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="fa-2x social-icon"
                    icon={faInstagram}
                    style={{ marginLeft: "10px" }}
                  />
                </a>

                <a
                  href="https://twitter.com/powerframesco"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="fa-2x social-icon"
                    icon={faTwitter}
                    style={{ marginLeft: "10px" }}
                  />
                </a>
              </Row>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <hr
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              border: 0,
              borderTop: "1px solid rgba(255, 255, 255, 0.5)",
            }}
          />
          <Col>
            <p>Copyright Innovation Frames, S.A.S. 2021-2022.</p>
            <p>
              <a
                href="https://keraunos.co/"
                title=""
                target="_blank"
                rel="noopener noreferrer"
                className="w3-hover-text-green a-white"
              >
                Filial de KERAUNOS LATAM{" "}
              </a>
            </p>
            <p>
              <a
                href="/privacyPolicy"
                title=""
                target="_blank"
                rel="noopener noreferrer"
                className="w3-hover-text-green a-white"
              >
                Privacy Policy{" "}
              </a>
            |{" "}
              <a
                href="https://www.powerframes.co"
                title=""
                target="_blank"
                rel="noopener noreferrer"
                className="w3-hover-text-green a-white"
              >
                Terms & Conditions
            </a>
            </p>
            <p>
              Power Frames, AMALIA and associated project names are trademarks of
            {"  "}
              <a
                href="https://www.powerframes.co"
                title=""
                target="_blank"
                rel="noopener noreferrer"
                className="w3-hover-text-green"
              >
              </a>
                Innovation Frames S.A.S (Filial de 
              {"  "}
                <a
                  href="https://keraunos.co/"
                  title=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w3-hover-text-green"
                >
                  KERAUNOS LATAM).
            </a>
            </p>
          </Col>
        </div>
      </Container>
    );
  }
}
export default Footer;
