import axios from "axios";

export const grafanaService = {
    login
};


function login(username: string, password: string) {
    //let payload = Buffer.from(`${username}:${password}`).toString('base64');
    const data = {
        "user": username,
        "password": password
    }

    return axios.post("https://grafana.powerframes.co/login",data, {withCredentials: true}).then(()=> {return true}).catch((err) => {return err})
}