import React, { ChangeEvent, Component, FormEvent } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import './login.css';
import { userActions } from "../../_actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { NavBar } from '../navBar/navBar.component'

interface myAlert {
    message: string,
    type: string
}

interface myProps {
    dispatch: Dispatch,
    loggingIn?: boolean,
    alert?: myAlert,
    state: {
        authentication?: Object,
        alert?: myAlert
    }
}

interface myState {
    name?: string,
    username?: string,
    password?: string,
    submitted?: boolean,
    authentication?: any,
    alert?: myAlert
}

class Login extends Component<myProps, myState> {
    constructor(props: myProps) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            dispatch<any>(userActions.login(username, password))
        }
        return false;
    }
    render() {
        const { username, password, submitted } = this.state;
        return (
            <div>
                <NavBar />
                <Modal.Dialog className="modal-dialog-centered">
                    <div className="row">
                        <div className="col-10">
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="col">
                                    <div className="row">
                                        <img alt="" className="sign-logo" src="signinportal.png"/>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <input type="text"
                                                className={'form-control' + (submitted && !username ? ' is-invalid' : '')}
                                                name="username"
                                                value={username}
                                                onChange={this.handleChange}
                                                placeholder="Usuario" />
                                            {submitted && !username &&
                                                <div className="help-block inline-errormsg">Username is required</div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <input type="password"
                                                className={'form-control' + (submitted && !password ? ' is-invalid' : '')}
                                                name="password" value={password}
                                                placeholder="Contraseña"
                                                onChange={this.handleChange} />
                                            {submitted && !password &&
                                                <div className="help-block inline-errormsg">Password is required</div>
                                            }
                                        </div>
                                    </div>
                                    <button className="btn btn-lg btn-primary btn-block" type="submit"
                                        name="submit">Ingresar
                                    </button>

                                    <Col className="d-flex flex-column mt-5">
                                        <Row className="copyright-row">
                                            <p style={{ paddingTop: "0px", fontSize: "small" }}>Copyright © 2020-2021, Innovation Frames, S.A.S.</p>
                                        </Row>
                                        <Row className="copyright-row">
                                            <p style={{ fontSize: "small" }}>
                                                <a href="http://www.powerframes.co" title="" target="_blank"
                                                    className="w3-hover-text-green" rel="noopener noreferrer">Privacy
                                            Policy</a> |
                                        <a href="http://www.powerframes.co" title="" target="_blank"
                                                    className="w3-hover-text-green" rel="noopener noreferrer"> Terms &
                                            Conditions</a>
                                            </p>
                                        </Row>
                                        <Row className="copyright-row">
                                            <p style={{ fontSize: "small", textAlign: "center" }}>Power Frames, AMALIA, and
                                            associated project names are
                                        trademarks of <a href="http://www.powerframes.co" title="" target="_blank"
                                                    className="w3-hover-text-green" rel="noopener noreferrer">Innovation
                                                    Frames
                                            S.A.S.</a></p>
                                        </Row>
                                    </Col>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Dialog>
            </div>
        );
    }
}

function mapStateToProps(state: myState) {
    return {
        state
    };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as Login };
