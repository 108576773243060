import { Component } from "react";
import "./privacyPolicy.component.css";
import { withTranslation } from "react-i18next";
import Footer from "../footer/footer.component";
import { connect } from "react-redux";
import { NavBar } from "../navBar/navBar.component"
import { NavBar as PortalNavBar } from '../modules/navBar/navBar.component'


class PrivacyPolicy extends Component<any,any> {
    constructor(props: any) {
        super(props);
        const { i18n } = this.props;
        i18n.changeLanguage("es");
    }
    render() {
        const user = this.props.state.authentication.user;
        return (
            <div>
                {user ? <PortalNavBar/> : <NavBar/>}
                <div className="privacy">
                    <h1>Aviso de privacidad de Power <strong>Frames</strong></h1>
                    <hr />
                    <a href="/PF_Aviso_de_Privacidad_2020-05-09.pdf" target="_blank" rel="noopener noreferrer">Version PDF</a>
                    <h2>Política de Privacidad</h2>
                    <p>
                        INNOVATION FRAMES S.A.S. con NIT. 901.402.403-9 domiciliada en Bogotá D.C, dando
                        cumplimiento de las normas contenidas en la Ley 1581 de 2012, en el Decreto 1377 de 2013 y en
                        las demás normas concordantes por las cuales se dictan disposiciones generales para la
                        protección de datos personales, y en vista de su calidad de Responsable del Tratamiento de Datos
                        Personales de conformidad con la aludida normatividad, se permite dar a conocer la presente
                        Política de Privacidad y de Protección de Datos Personales (en adelante la “Política”) para regular
                        la recolección, almacenamiento, tratamiento, administración, transferencia, transmisión,
                        protección y supresión de aquella información que se reciba de los titulares de datos personales
                        o de terceros a través de los diferentes canales de recolección de datos que ha dispuesto en el
                        desarrollo de sus actividades.
                        INNOVATION FRAMES SAS desarrollo la aplicación como una aplicación comercial. Este SERVICIO
                        es proporcionado por INNOVATION FRAMES SAS y está destinado a ser utilizado tal cual.
                        Esta página se utiliza para informar a los visitantes sobre nuestras políticas con la recopilación,
                        uso y divulgación de Información personal si alguien decide utilizar nuestro Servicio.
                        Si elige utilizar nuestro Servicio, entonces acepta la recopilación y el uso de información en
                        relación con esta política. La información personal que recopilamos se utiliza para proporcionar y
                        mejorar el servicio. No usaremos ni compartiremos su información con nadie excepto como se
                        describe en esta Política de Privacidad.
                        Los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros
                        Términos y condiciones, a los que se puede acceder a menos que se defina lo contrario en esta
                        Política de privacidad.
        </p>
                    <br />
                    <h2>Recolección de información y uso</h2>
                    <p>
                        <strong>Definiciones</strong>: De acuerdo con los conceptos y criterios de la Ley Estatutaria 1581 de 2012 y el
                    decreto reglamentario 1377 de 2103 y/o para efectos de la presente Política, las palabras que a
                    continuación se definen tendrán el significado asignado en este capítulo, sea que se escriban o
                    no en mayúsculas, o que se encuentren en plural o singular, los mismos serán desarrollados y
                    aplicados bajo una interpretación sistemática e integral establecidos en la mencionada
                    normatividad.
                    </p>
                    <ol type="i">
                        <li><strong>Aviso de privacidad</strong>: Comunicación generada por el Responsable, dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales. </li>
                        <li><strong>Autorización</strong>: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales. </li>
                        <li><strong>Base de Datos</strong>: Conjunto organizado de Datos Personales que sea objeto de Tratamiento, los cuales pueden ser almacenados y/o procesados en servidores ubicados en centros de cómputo o papelería física, ya sean propios o contratados con terceros, localizados en el territorio nacional o en distintos países. </li>
                        <li><strong>Dato Personal</strong>: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. </li>
                        <li><strong>Dato público</strong>: es el dato que no sea semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva. </li>
                        <li><strong>Datos Sensibles</strong>: Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros. </li>
                        <li><strong>Encargado del Tratamiento</strong>: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento de Datos Personales. </li>
                        <li><strong>Responsable del Tratamiento</strong>: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos. Quien, para efectos de la presente Política, el Responsable será INNOVATION FRAMES SAS. </li>
                        <li><strong>Titular</strong>: Persona natural cuyos datos personales sean objeto de Tratamiento y Persona Jurídica en el evento de Tratamiento sus datos de índole financiera, sea que estos ostenten la calidad de clientes, empleados, proveedores u otra clase de denominación frente a la empresa. </li>
                        <li><strong>Transferencia</strong>: la transferencia de datos tiene lugar cuando el responsable y/o encargado del tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.</li>
                        <li><strong>Transmisión</strong>: tratamiento de datos personales que implica la comunicación de estos dentro o fuera del territorio de la Republica de Colombia cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta de un único responsable.</li>
                    </ol>
                    <h2>Obligaciones de los usuarios</h2>
                    <p>El Usuario se obliga a usar la aplicación y los contenidos encontrados en ella de una manera
                    diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a
continuación: </p>
                    <ol type="a">
                        <li>Utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público; </li>
                        <li>Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial, o permitir el acceso del público a través de cualquier modalidad de comunicación pública; </li>
                        <li>Utilizar los contenidos de cualquier manera que entrañen un riesgo de daño o inutilización de la aplicación o de los contenidos o de terceros; </li>
                        <li>Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenidos; </li>
                        <li>Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la aplicación para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como comercializar o divulgar de cualquier modo dicha información; </li>
                        <li>No permitir que terceros ajenos a usted usen la aplicación móvil con su clave; </li>
                        <li>Utilizar la aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o al uso mismo de la aplicación, que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la aplicación y los contenidos o impedir la normal utilización o disfrute de esta y de los contenidos por parte de los usuarios.</li>
                    </ol>
                    <p>En el Evento en que un Usuario incumpla estos Términos y Condiciones, o cualesquiera otras disposiciones que resulten de aplicación, INNOVATION FRAMES podrá suspender su acceso a la aplicación. </p>
                    <p>Queremos informarle que cada vez que utiliza nuestro Servicio, en caso de un error en la
                    aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono
                    llamado Datos de registro. Estos Datos de registro pueden incluir información como la dirección
                    de Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema
                    operativo, la configuración de la aplicación cuando utiliza nuestro Servicio, la hora y fecha de su
                    uso del Servicio y otras estadísticas.
</p>
                    <br />
                    <h3>Cookies</h3>
                    <p>Las cookies son archivos con una pequeña cantidad de datos que se utilizan comúnmente como
                    identificadores únicos anónimos. Estos se envían a su navegador desde los sitios web que visita y
se almacenan en la memoria interna de su dispositivo. <br />
Este Servicio no utiliza estas "cookies" explícitamente. Sin embargo, la aplicación puede utilizar
códigos de terceros y bibliotecas que utilizan "cookies" para recopilar información y mejorar sus
servicios. Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía una cookie
a su dispositivo. Si opta por rechazar nuestras cookies, es posible que no pueda utilizar algunas
partes de este Servicio.
</p>
                    <br />
                    <h3>Proveedores de servicio</h3>
                    <p>Podemos emplear empresas e individuos de terceros por las siguientes razones:
</p>
                    <ul>
                        <li>Para facilitar nuestro Servicio; </li>
                        <li>Para proporcionar el Servicio en nuestro nombre; </li>
                        <li>Para realizar servicios relacionados con el Servicio; o </li>
                        <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li>
                    </ul>
                    <p>
                        Queremos informar a los usuarios de este Servicio que estos terceros tienen acceso a su
                        Información personal. El motivo es realizar las tareas que se les asignen en nuestro nombre. Sin
embargo, están obligados a no divulgar ni utilizar la información para ningún otro propósito. </p>
                    <br />
                    <h3>Seguridad</h3>
                    <p>Valoramos su confianza al proporcionarnos su información personal, por lo que nos esforzamos
                    por utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ningún
                    método de transmisión a través de Internet o método de almacenamiento electrónico es 100%
                    seguro y confiable, y no podemos garantizar su absoluta seguridad.
</p>
                    <br />
                    <h3>Enlaces a otros sitios</h3>
                    <p>Este Servicio puede contener enlaces a otros sitios. Si hace clic en un enlace de un tercero, se le
                    dirigirá a ese sitio. Tenga en cuenta que estos sitios externos no son operados por nosotros. Por
                    lo tanto, le recomendamos encarecidamente que revise la Política de privacidad de estos sitios
                    web. No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas
de privacidad o las prácticas de los sitios o servicios de terceros.</p>
                    <br />
                    <h3>Privacidad infantil</h3>
                    <p>Estos Servicios no se dirigen a ninguna persona menor de 18 años. No recopilamos a sabiendas
                    información de identificación personal de niños menores de 18 años. En el caso de que
                    descubramos que un niño menor de 18 años nos ha proporcionado información personal, la
                    eliminamos inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe que su hijo
                    nos ha proporcionado información personal, comuníquese con nosotros para que podamos
realizar las acciones necesarias. </p>
                    <br />
                    <h3>Contactenos</h3>
                    <p>Si tiene alguna pregunta o sugerencia, por favor contacte a <a href="mailto:info@powerframes.co">info@powerframes.co</a>.</p>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedPrivacyPolicy = withTranslation()(connect(mapStateToProps)(PrivacyPolicy));

export { connectedPrivacyPolicy as PrivacyPolicy };

