import { authHeader, config } from '../_helpers';
import jwt from 'jwt-decode';
import axios from "axios";

export const userService = {
    login,
    logout,
    getAll,
    refreshToken
};

let user = "";

function login(username: string, password: string) {
    user = username;
    const requestOptions = {
        username,
        password,
        client_id: config.client_id,
        client_secret: config.client_secret
    }

    return axios.post(`${config.backend_url}/auth/login`,requestOptions)
        .then(handleCode)
        .then(getUserInfo)
        .then(u => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(u));
            return u;
        });
}


function getUserInfo(me:any) {
    const info = jwt(me.accessToken)
    return Promise.resolve(Object.assign(me,info))
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll(): Promise<any> {
    const requestOptions = {
        method: "GET" as "GET",
        url: `${config.backend_url}/users`,
        headers: authHeader()
    }
    return axios(requestOptions).then(handleUsers).catch((err) => {
        refreshToken().then(getAll)
    });
}

function handleCode(response: any) {
    if (response.status > 499) {
        logout();
        window.location.reload();
        return Promise.reject()
    }else {
        const requestOptions = {
            username: user ? user : JSON.parse(localStorage.getItem('user') as string).username,
            code: response.data.code,
            clientId: config.client_id,
        }
        return axios.post(`${config.backend_url}/auth/token`, requestOptions)
            .then(r => {
                if (r.status > 499) {
                    logout();
                    window.location.reload();
                } else {
                    return Promise.resolve(r.data);
                }
            });
    }
}

function refreshToken() {
    const localUser = JSON.parse(localStorage.getItem('user') as string);
    const requestOptions = {
        method: "POST" as "POST",
        url: `${config.backend_url}/auth/token-refresh`,
        data: {refreshToken: localUser.refreshToken}
    }
    return axios(requestOptions).then(res => {
        getUserInfo(res.data).then(u => {
            console.log(u);
            localStorage.setItem('user', JSON.stringify(u));
            return Promise.resolve(u);
        })
    });
}

function handleUsers(response:any):any {
    if (response.status > 499) {
        logout();
        window.location.reload();
    } else {
        return Promise.resolve(response)
    }
}
