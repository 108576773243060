import React, { Component } from "react";
import "./form.css";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { reportsActions } from "../../_actions";
import PauseButton from "./pauseButton";
import * as lineas from "./itco-lista-circuitos-20240219.json";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let months = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};
const currentYear: number = new Date().getFullYear();
class Historic extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: props.state.authentication.user.username, //username
      agent: "ITCO", // SLUG WORK TO DO: El dato debe ser dinamico y la información debe provenir de la DB
      circuit: undefined,
      timeType: undefined,
      lineType: "",
      tower_ini: "",
      tower_end: "",
      year: undefined,
      month: 0,
      email_list: undefined,
      // showInfo: "corr"
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.corrForm = this.corrForm.bind(this);
    // this.pointForm = this.pointForm.bind(this);
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  // corrForm() {
  //   this.setState({ ["showInfo"]: "corr" })
  // }
  // pointForm() {
  //   this.setState({ ["showInfo"]: "point" })
  // }

  async handleSubmit() {
    const { dispatch } = this.props;
    dispatch(reportsActions.historicEvent(this.state));
    await sleep(120000);
    console.log(this.state);
  }

  render() {
    return (
      <Container
        className="Form"
        fluid
        style={{ minHeight: "89.6vh", paddingTop: 40 }}
      >
        <Row>
          <h3>Formulario de solicitud de reportes históricos</h3>
        </Row>
        <Row>
          <h6>
            Llene por favor los campos presentados a continuación, para generar
            automaticamente un reporte histórico será enviado a su correo.
          </h6>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 pt-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-2">
            <label>Línea</label>
          </Col>
          <Col className="col-sm-2">
            <select
              id="circuit"
              className="form-control"
              name="circuit"
              value={this.state.circuit}
              onChange={this.handleInputChange}
              placeholder="Seleccione la línea"
            >
              <option></option>
              {Object.keys(lineas.ITCO).map((linea) => {
                return (
                  <option key={linea} value={linea}>
                    {linea}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col className="col-sm-3">
            <select
              id="lineType"
              className="form-control"
              name="lineType"
              value={this.state.lineType}
              onChange={this.handleInputChange}
              placeholder=""
            >
              <option disabled={true} value="">
                Seleccione el tipo de reporte
              </option>
              <option value="full">Linea completa</option>
              <option value="partial">Rango de torres</option>
            </select>
          </Col>

          {this.state.lineType === "partial" && this.state.circuit ? (
            <Col className="col-sm-2">
              <select
                id="tower_ini"
                className="form-control"
                name="tower_ini"
                value={this.state.tower_ini}
                onChange={this.handleInputChange}
                placeholder=""
              >
                <option disabled={true} value="">
                  Torre inicial
                </option>
                {lineas.ITCO[
                  this.state.circuit as keyof typeof lineas.ITCO
                ].towers_list.map((tower) => {
                  return (
                    <option key={tower} value={tower}>
                      {tower}
                    </option>
                  );
                })}
              </select>
            </Col>
          ) : null}

          {this.state.lineType === "partial" && this.state.circuit ? (
            <Col className="col-sm-2">
              <select
                id="tower_end"
                className="form-control"
                name="tower_end"
                value={this.state.tower_end}
                onChange={this.handleInputChange}
                placeholder=""
              >
                <option disabled={true} value="">
                  Torre final
                </option>
                {lineas.ITCO[
                  this.state.circuit as keyof typeof lineas.ITCO
                ].towers_list.map((tower) => {
                  return (
                    <option key={tower} value={tower}>
                      {tower}
                    </option>
                  );
                })}
              </select>
            </Col>
          ) : null}
        </Row>

        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-2">
            <label>Periodo</label>
          </Col>
          <Col className="col-sm-2">
            <select
              id="timeType"
              className="form-control"
              name="timeType"
              value={this.state.timeType}
              onChange={this.handleInputChange}
              placeholder="Seleccione el tipo de periodo"
            >
              <option></option>

              <option value="annually">Anual</option>
              <option value="monthly">Mensual</option>
            </select>
          </Col>
          <Col className="col-sm-1">
            <label>Año</label>
          </Col>
          <Col className="col-sm-2">
            <select
              id="year"
              className="form-control"
              name="year"
              value={this.state.year}
              onChange={this.handleInputChange}
              placeholder="Seleccione el año"
            >
              <option></option>
              {Array(this.state.timeType === "annually" ? 3 : 4)
                .fill(2021)
                .map((x, y) => x + y)
                .map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
            </select>
          </Col>
          {this.state.timeType === "monthly" && this.state.year ? (
            <Col className="col-sm-2">
              <label>Mes</label>
            </Col>
          ) : null}

          {this.state.timeType === "monthly" && this.state.year ? (
            <Col className="col-sm-2">
              <select
                id="month"
                className="form-control"
                name="month"
                value={this.state.month}
                onChange={this.handleInputChange}
                placeholder="Seleccione el mes"
              >
                <option></option>
                {Object.keys(months)
                  .slice(
                    0,
                    this.state.year === currentYear.toString()
                      ? new Date().getMonth()
                      : 12
                  )
                  .map((month) => {
                    return (
                      <option
                        key={month}
                        value={months[month as keyof typeof months]}
                      >
                        {month}
                      </option>
                    );
                  })}
              </select>
            </Col>
          ) : null}
        </Row>

        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-lg-2 col-sm-12">
            <label>Correo electrónico</label>
          </Col>
          <Col className="col-lg-9 col-sm-12">
            <input
              required
              multiple
              className="form-control"
              type="email"
              name="email_list"
              id="email_list"
              value={this.state.email_list}
              onChange={this.handleInputChange}
              placeholder="Enviar reporte a correo electrónico"
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>Puede ingresar varios correos, separados por coma.</label>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} className="text-center pb-3">
          <PauseButton
            style={{ width: "200px" }}
            className="btn btn-lg btn-primary "
            type="submit"
            name="submit"
            disabled={
              !(
                this.state.circuit &&
                this.state.year &&
                this.state.email_list &&
                (this.state.timeType === "annually" ||
                  (this.state.timeType === "monthly" && this.state.month)) &&
                (this.state.lineType === "full" ||
                  (this.state.lineType === "partial" &&
                    this.state.tower_ini &&
                    this.state.tower_end))
              )
            }
            onClick={this.handleSubmit}
          >
            Enviar solicitud
          </PauseButton>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    state,
  };
};

const connectedHistoric = connect(mapStateToProps)(Historic);
export { connectedHistoric as Historic };
