import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { kibana } from './kibana.reducer';
import { grafana } from './grafana.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  kibana,
  grafana,
  alert
});

export default rootReducer;