import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import translationES from "./assets/i18n/translations/es.json";
import translationEN from "./assets/i18n/translations/en.json";

const resources = {
    es: {
      translations: translationES
    },
    en: {
      translations: translationEN
    }
  };

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'es',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            useSuspense: true
        }
    })

export default i18n
