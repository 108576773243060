
export function grafana(state = {}, action:any) {
    switch (action.type) {
      case "GRAFANA_LOGIN_SUCCESS":
          return {
              login: true
          }
      default:
        return state
    }
  }
  