import './App.css';
import './i18n';
import { Router } from "react-router-dom";
import { history } from './_helpers';
import { PrivateRoute } from "./_components";
import { Home, LandingPage, Login, Company, Reports, Dashboard, AtmosphericPredictions, Products, PrivacyPolicy, MassMovementPredictions } from "./components";
import { Switch, Route } from "react-router-dom";


function App() {
    return (
        <Router history={history}>
            <div className="App">
                <Switch>
                    <Route exact path='/' component={LandingPage} />
                    <Route path="/login" component={Login} />
                    <Route path="/company" component={Company} />
                    <Route path="/products" component={Products} />
                    <Route path="/privacyPolicy" component={PrivacyPolicy} />
                    <PrivateRoute path="/home" component={Home} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/atmosphericPredict" component={AtmosphericPredictions} />
                    <PrivateRoute path="/massPredict" component={MassMovementPredictions} />
                    <PrivateRoute path="/report" component={Reports} />
                </Switch>
            </div>
        </Router>

    );
}

export default App;
