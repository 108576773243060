import React from 'react';
import './footer.component.css'
export default function Footer() {
    return (
        <>
            <div className="d-flex flex-row justify-content-between" style={{ background: "black", height: "2vh", minHeight: "20px" }}>
                <div>
                    <div ><p className="footer-text">Power <strong>Frames</strong></p></div>
                </div>
                <div>
                    <div className="d-flex flex-column align-self-center"><p className="footer-text">© 2020 - 2021, INNOVATION FRAMES, S.A.S. or its affiliates. All rights reserved.</p></div>
                </div>
                <div>
                    <div className="d-flex flex-column align-self-center"><p className="footer-text"><a className="a-white" href="/privacyPolicy" target="_blank" rel="noopener noreferrer">Políticas de privacidad</a></p></div>
                </div>
            </div>
        </>
    )
}