import { ChangeEvent, Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { kibanaActions, grafanaActions } from "../../../_actions";
import './dashboard.component.css'
import classNames from "classnames"
import { Navbar, Modal, Button } from "react-bootstrap";
import Swal from 'sweetalert2'
import { NavBar } from "../navBar/navBar.component";
import { SideBar } from "../sideBar/sideBar.component"
import Footer from "../footer/footer.component"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const RELOAD_PERIOD_MINUTES = 10;
const RELOAD_PERIOD_MS = RELOAD_PERIOD_MINUTES * 60 * 1000;
const serviceError = false
class Dashboard extends Component<any, any> {
    constructor(props: any) {
        super(props);
        
        const { dispatch } = this.props;
        dispatch(kibanaActions.login("intercolombia", "eeZEjjvfuwm89RR"));
        dispatch(grafanaActions.login("intercolombia", "eeZEjjvfuwm89RR"));
        this.state = {
            interval: 0,
            rfShow: false,
            dfShow: false,
            initHourRelative: null,
            initMinRelative: null,
            endHourRelative: null,
            endMinRelative: null,
            initDate: null,
            endDate: null,
            mainFrameCurrent: 0,
            defaultQueryMainFrame: "&_g=(filters:!(),refreshInterval:(pause:!f,value:15000),time:(from:now-10m,to:now))",
            defaultQueryRecuento: "&from=now-10m&to=now",
            defaultQueryDensidad: "&from=now-10m&to=now",
            queryMainFame: "&_g=(filters:!(),refreshInterval:(pause:!f,value:15000),time:(from:now-10m,to:now))",
            queryRecuento: "&from=now-10m&to=now",
            defaultMapaPrincipalUrl: "https://kibana.powerframes.co/app/dashboards#/view/93148e50-493e-11eb-8b33-1964a7ecc180?embed=true&hide-filter-bar=true",
            defaultDatosUrl: "https://kibana.powerframes.co/app/dashboards#/view/14fa2c90-4ebc-11eb-a263-5b2cbea191cf?embed=true&hide-filter-bar=true",
            defaultReanalisisUrl: "https://kibana.powerframes.co/app/dashboards#/view/36aa9c20-5ac5-11eb-93c5-0d297de66f48?embed=true&hide-filter-bar=true",
            defaultEstadisticoUrl: "https://kibana.powerframes.co/app/dashboards#/view/5acd9c10-5aca-11eb-93c5-0d297de66f48?embed=true&hide-filter-bar=true",
            defaultRecuentoUrl: "https://grafana.powerframes.co/d-solo/vQue0y-Gk/recuento-de-rayos-por-linea?orgId=1&panelId=2&theme=dark&refresh=10s",
            mainFrameSrc: "https://kibana.powerframes.co/app/dashboards#/view/93148e50-493e-11eb-8b33-1964a7ecc180?embed=true&hide-filter-bar=true&_g=(filters:!(),refreshInterval:(pause:!f,value:15000),time:(from:now-10m,to:now))",
            recuentoSrc: "https://grafana.powerframes.co/d-solo/vQue0y-Gk/recuento-de-rayos-por-linea?orgId=1&panelId=2&theme=dark&refresh=10s&from=now-10m&to=now",
            densidadSrc: "https://grafana.powerframes.co/d-solo/3XS6YHM7k/dsd?orgId=1&refresh=1m&panelId=2&theme=dark&from=now-10m&to=now",
            defaultDensidadUrl: "https://grafana.powerframes.co/d-solo/3XS6YHM7k/dsd?orgId=1&refresh=1m&panelId=2",
            defaultFallas: "https://grafana.powerframes.co/d-solo/cDaFGOM7k/probabilidad-dsd?orgId=1&from=now-10m&to=now&refresh=1m&panelId=2",
            recuento: false,
            dsd: false,
            cuadrillas: false,
            fallas: false,
            showMap: true,
            title: "Tiempo Real (Últimos 10 minutos)",
            isOpen: false,
            isMobile: true,
            previousWidth: -1,
            enableReset: false,
            reload : 0
        }
        this.handleDateFilter = this.handleDateFilter.bind(this);
        this.handleRelativeFilter = this.handleRelativeFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAmaliaToogle = this.handleAmaliaToogle.bind(this);
        this.handleFilterToogle = this.handleFilterToogle.bind(this);
    }

    hideRecuento = () => {
        if(this.state.recuento === false){
            this.setState({
                recuento: true,
                dsd: false,
                fallas: false
            })

        } else {
            this.setState({
                recuento: false
            })
        }
        
    }

    hideDsd = () => {
        if(this.state.dsd === false){
            this.setState({
                recuento: false,
                dsd: true,
                fallas: false
            })

        } else {
            this.setState({
                dsd: false
            })
        }
    }

    hideFallas = () => {
        if(this.state.fallas === false){
            this.setState({
                recuento: false,
                dsd: false,
                fallas: true
            })

        } else {
            this.setState({
                fallas: false
            })
        }
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    handleDateFilter() {
        if (this.state.endDate) {
            if (new Date(this.state.endDate).getTime() <= new Date(this.state.initDate).getTime()) {
                Swal.fire({
                    title: 'Las fechas no coinciden',
                    text: "Por favor, verifique que la fecha y hora de inicio sea menor a la de fin",
                    icon: 'warning',
                })
                return;
            }
        }
        let title = "Desde el "
        title += `${this.state.initDate.toLocaleString("es-CO", { dateStyle: "long", timeStyle: "short" })} hasta `
        if (this.state.endDate) {
            title += `${this.state.endDate.toLocaleString("es-CO", { dateStyle: "long", timeStyle: "short" })}`
        } else {
            title += 'ahora'
        }
        let queryKB = `&_g=(filters:!(),refreshInterval:(pause:!t,value:15000),time:(from:'${new Date(this.state.initDate).toISOString()}',to:'${this.state.endDate ? new Date(this.state.endDate).toISOString() : "now"}'))`
        let queryGF = `&from=${new Date(this.state.initDate).getTime()}&to=${this.state.endDate ? new Date(this.state.endDate).getTime() : "now"}`
        let currentMainFrameSrc = ""
        switch (this.state.mainFrameCurrent) {
            case 0:
                currentMainFrameSrc = this.state.defaultMapaPrincipalUrl
                break
            case 1:
                currentMainFrameSrc = this.state.defaultDatosUrl
                break
            case 2:
                currentMainFrameSrc = this.state.defaultReanalisisUrl
                break
            case 3:
                currentMainFrameSrc = this.state.defaultEstadisticoUrl
                break
        }
        this.setState({
            dfShow: false,
            title: title,
            enableReset: true,
            queryMainFame: queryKB,
            queryRecuento: queryGF,
            mainFrameSrc: currentMainFrameSrc + queryKB,
            recuentoSrc: this.state.defaultRecuentoUrl + queryGF,
            densidadSrc: this.state.defaultDensidadUrl + queryGF
        })

    };

    reloadDashboard = () => {
        this.setState({
            reload : this.state.reload + 1
        })
        console.log(`${new Date()} - Dashboard reloaded`)
    };

    componentDidMount() {
        let interval = setInterval(() => {
            this.reloadDashboard();
        }, RELOAD_PERIOD_MS);
        this.setState({interval : interval})
    };

    componentWillUnmount() {
        clearInterval(this.state.interval);
    };

    handleRelativeFilter() {
        const init = (this.state.initHourRelative * 60) + this.state.initMinRelative
        const end = (this.state.endHourRelative * 60) + this.state.endMinRelative
        if (init <= end) {
            Swal.fire({
                title: 'Las fechas no coinciden',
                text: "Por favor, verifique que la fecha y hora de inicio sea menor a la de fin",
                icon: 'warning',
            })
        } else {
            let title = "Desde ";
            if (this.state.initHourRelative > 0) {
                title += `${this.state.initHourRelative} hora(s) `
                if (this.state.initMinRelative > 0) {
                    title += `y ${this.state.initMinRelative} minuto(s) atrás `
                } else {
                    title += `atrás `
                }
            } else if (this.state.initMinRelative > 0) {
                title += `${this.state.initMinRelative} minuto(s) atrás `
            } else {
                title += "atrás "
            }

            if (this.state.endHourRelative > 0) {
                title += `hasta ${this.state.endHourRelative} hora(s) `
                if (this.state.endMinRelative > 0) {
                    title += `y ${this.state.endMinRelative} minuto(s) atrás `
                } else {
                    title += `atrás `
                }
            } else if (this.state.endMinRelative > 0) {
                title += `hasta ${this.state.endMinRelative} minuto(s) atrás `
            } else {
                title += "hasta ahora"
            }
            if (title.length < 18) {
                title = "Tiempo Real"
            }
            let queryKB = `&_g=(filters:!(),refreshInterval:(pause:!t,value:15000),time:(from:now-${(this.state.initHourRelative * 60) + this.state.initMinRelative}m,to:now-${(this.state.endHourRelative * 60) + this.state.endMinRelative}m))`
            let queryGF = `&from=now-${(this.state.initHourRelative * 60) + this.state.initMinRelative}m&to=now-${(this.state.endHourRelative * 60) + this.state.endMinRelative}m`
            let currentMainFrameSrc = ""
            switch (this.state.mainFrameCurrent) {
                case 0:
                    currentMainFrameSrc = this.state.defaultMapaPrincipalUrl
                    break
                case 1:
                    currentMainFrameSrc = this.state.defaultDatosUrl
                    break
                case 2:
                    currentMainFrameSrc = this.state.defaultReanalisisUrl
                    break
                case 3:
                    currentMainFrameSrc = this.state.defaultEstadisticoUrl
                    break
            }
            this.setState({
                queryMainFame: queryKB,
                queryRecuento: queryGF,
                enableReset: true,
                rfShow: false,
                title: title,
                mainFrameSrc: currentMainFrameSrc + queryKB,
                recuentoSrc: this.state.defaultRecuentoUrl + queryGF,
                densidadSrc: this.state.defaultDensidadUrl + queryGF
            })
        }
    }

    handleAmaliaToogle(option: number) {
        switch (option) {
            case 0:
                this.setState({
                    showMap: true,
                    isOpen: false,
                    mainFrameCurrent: 0,
                    mainFrameSrc: this.state.defaultMapaPrincipalUrl + this.state.queryMainFame
                })
                return
            case 1:
                this.setState({
                    showMap: false,
                    isOpen: false,
                    mainFrameCurrent: 1,
                    mainFrameSrc: this.state.defaultDatosUrl + this.state.queryMainFame
                })
                return
            case 2:
                this.setState({
                    showMap: false,
                    isOpen: false,
                    mainFrameCurrent: 2,
                    mainFrameSrc: this.state.defaultReanalisisUrl + this.state.queryMainFame
                })
                return
            case 3:
                this.setState({
                    showMap: false,
                    isOpen: false,
                    mainFrameCurrent: 3,
                    mainFrameSrc: this.state.defaultEstadisticoUrl + this.state.queryMainFame
                })
                return
            default:
                this.setState({ showMap: false })
                return
        }

    }
    handleFilterToogle(option: number) {
        switch (option) {
            case 0:
                this.setState({ rfShow: true, dfShow: false, isOpen: false })
                break
            case 1:
                this.setState({ rfShow: false, dfShow: true, isOpen: false })
                break
            case 2:
                let currentMainFrameSrc = ""
                switch (this.state.mainFrameCurrent) {
                    case 0:
                        currentMainFrameSrc = this.state.defaultMapaPrincipalUrl
                        break
                    case 1:
                        currentMainFrameSrc = this.state.defaultDatosUrl
                        break
                    case 2:
                        currentMainFrameSrc = this.state.defaultReanalisisUrl
                        break
                    case 3:
                        currentMainFrameSrc = this.state.defaultEstadisticoUrl
                        break
                }
                this.setState({
                    initHourRelative: null,
                    initMinRelative: null,
                    endHourRelative: null,
                    endMinRelative: null,
                    initDate: null,
                    endDate: null,
                    enableReset: false,
                    isOpen: false,
                    queryMainFame: this.state.defaultQueryMainFrame,
                    queryRecuento: this.state.defaultQueryRecuento,
                    title: "Tiempo Real",
                    mainFrameSrc: currentMainFrameSrc + this.state.defaultQueryMainFrame,
                    recuentoSrc: this.state.defaultRecuentoUrl + this.state.defaultQueryRecuento,
                    densidadSrc: this.state.defaultDensidadUrl + this.state.defaultQueryDensidad
                })
                break
        }

    }

    render() {
        if (!this.props.state.authentication.user) {
            return (<Redirect to={'/login'} />)
        } else {
            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Power Frames &gt; Dashboard</title>
                    </Helmet>
                    <NavBar toggle={this.toggle} title={this.state.title} />
                    {serviceError?<div style={{display: 'flex', backgroundColor:'#219ebc', alignItems: 'center',justifyContent: 'center', color:'white'}}><FontAwesomeIcon icon={faInfoCircle} style={{marginRight:'20px'}} />  Disrupción en la fuente de datos de rayos alta precisión desde las 7:00 hora local.</div>:undefined}
                    {serviceError?<div style={{display: 'flex', backgroundColor:'#219ebc', alignItems: 'center',justifyContent: 'center', color:'white'}}>Actualmente algunos servicios se encuentran indisponibles. Para mayor información contactarse con support@powerframes.co.</div>:undefined}
                    
                    <div className="wrapper">
                        <SideBar toggle={this.toggle} handleFunctions={[this.handleAmaliaToogle, this.handleFilterToogle]} showReset={this.state.enableReset} isOpen={this.state.isOpen} />
                        <div className={classNames("cont", { "is-open": this.props.isOpen })}>
                            <div className={this.state.showMap ? "frame1" : "frame5"}>
                                <iframe key={this.state.reload} title="map" id="frame1" src={this.props.state.kibana.login ? this.state.mainFrameSrc : ""} />
                            </div>
                            <div className="recuento" style={{ display: this.state?.recuento ? "block" : "none" }}>
                                <iframe title="storm" src={this.props.state.grafana.login ? this.state.recuentoSrc : ""} />
                            </div>
                            <div className="dsd" style={{ display: this.state?.dsd ? "block" : "none" }}>
                                <iframe title="dsd" src={this.props.state.grafana.login ? this.state.densidadSrc : ""} />
                            </div>
                            <div className="fallas" style={{ display: this.state?.fallas ? "block" : "none" }}>
                                <iframe title="fallas" src={this.props.state.grafana.login ? this.state.defaultFallas : ""} />
                            </div>
                            {
                                this.state.showMap ?

                                    <div className="bottom-nav">
                                        <Navbar className="nav" bg="dark" variant="dark" expand={false}>
                                            <Button className="bg-dark text-white bg-bar rounded border-secondary btn" size="sm" onClick={this.hideRecuento}>Recuento de rayos</Button>
                                            <Button className="bg-dark text-white bg-bar rounded border-secondary btn" size="sm" onClick={this.hideDsd}>Densidad dínamica de rayos</Button>
                                            <Button className="bg-dark text-white bg-bar rounded border-secondary btn" size="sm" onClick={this.hideFallas} >Probabilidad de Falla</Button>
                                        </Navbar>
                                    </div> :
                                    null
                            }
                        </div>
                    </div>
                    <Footer />
                    <div>
                        <Modal
                            show={this.state.rfShow}
                            onHide={() => this.setState({ rfShow: false })}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Filtro Relativo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div id="interval-form">
                                    <div className={"d-flex flex-column justify-content-between"} style={{ height: "100px" }}>
                                        <div className={"d-flex flex-row justify-content-around"}>
                                            <div>
                                                <p>Desde:</p>
                                                <input onChange={this.handleChange} name="initHourRelative" value={this.state.initHourRelative} type="number" placeholder={"HH"} style={{ width: "50px" }} />
                                                :
                                                <input onChange={this.handleChange} name="initMinRelative" value={this.state.initMinRelative} type="number" placeholder={"mm"} style={{ width: "50px" }} />
                                            </div>
                                            <div>
                                                <p>Hasta:</p>
                                                <input onChange={this.handleChange} name="endHourRelative" value={this.state.endHourRelative} type="number" placeholder={"HH"} style={{ width: "50px" }} />
                                                :
                                                <input onChange={this.handleChange} name="endMinRelative" value={this.state.endMinRelative} type="number" placeholder={"mm"} style={{ width: "50px" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className={"d-flex flex-row-reverse bd-highlight mb-3"}>
                                    <button onClick={this.handleRelativeFilter} className={"btn btn-success"} disabled={!(this.state.initHourRelative || this.state.initMinRelative) || ((this.state.initHourRelative < 0 || this.state.initMinRelative < 0) || (this.state.endHourRelative < 0 || this.state.endMinRelative < 0))} id="interval-submit">Cambiar intervalo</button>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.dfShow}
                            onHide={() => this.setState({ dfShow: false })}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Filtro por fecha</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div id="interval-form">
                                    <div className={"d-flex flex-column justify-content-between"} style={{ height: "100px" }}>
                                        <div className={"d-flex flex-row justify-content-between"}>
                                            <div>
                                                <p>Fecha inicio:</p>
                                                <input
                                                    type="datetime-local"
                                                    min="2020-07-10"
                                                    max={new Date().toLocaleDateString("en-ZA").replaceAll('/','-')}
                                                    className="form-control"
                                                    id="datetime"
                                                    name="datetime"
                                                    value={this.state.initDate}
                                                    onChange={event => {console.log(event.target.value);this.setState({ initDate: event.target.value })}}
                                                />
                                            </div>
                                            <div>
                                                <p>Fecha fin:</p>
                                                <input
                                                    type="datetime-local"
                                                    min="2020-07-10"
                                                    className="form-control"
                                                    id="datetime"
                                                    name="datetime"
                                                    max={new Date().toLocaleDateString("en-ZA").replaceAll('/','-')}
                                                    value={this.state.endDate}
                                                    onChange={event => {console.log(event.target.value);this.setState({ endDate: event.target.value })}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className={"d-flex flex-row-reverse bd-highlight mb-3"}>
                                    <button onClick={this.handleDateFilter} disabled={!this.state.initDate} className={"btn btn-success"} id="interval-submit">Cambiar fechas</button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedDashboard = connect(mapStateToProps)(withRouter(Dashboard));
export { connectedDashboard as Dashboard };
