import { Component } from "react";
import "./company.css";
import { WithTranslation, withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../footer/footer.component";
import { NavBar } from "../navBar/navBar.component"

class Company extends Component<WithTranslation> {
  constructor(props: WithTranslation) {
    super(props);
    const { i18n } = this.props;
    i18n.changeLanguage("es");
  }
  render() {
    return (
      <div>
        <NavBar/>
        <Container
          fluid
          style={{ backgroundColor: "#000000", top: 0, paddingTop: "100px" }}
        >
          <div>
            <h3 className="text-center" style={{color: "#FFFFFF", top: 0 }} >
              ¿Quiénes somos?
            </h3>
          </div>
          <Row style = {{marginTop:"40px"}}>
            <h5 className="text-center" style={{color: "#FFFFFF", top: 0, paddingBottom: "100px" }} >
            Somos una empresa con más de 4 años de trayectoria, la cual ha enfocado su investigación y desarrollo teconológico en el 
            estudio y la caracterización de las descargas atmosféricas, lo que ha permitido no sólo la supervisión, monitoreo, generación de reportes 
            y datos históricos, sino también la posibilidad de la predicción de estos eventos. 
            La empresa cuenta con un área destinada al análisis de datos que permiten el estudio de patrones de detección y predicción, un
            área de arquitectura que garantiza la estabilidad y disponibilidad de la información en la nube para las diferentes necesidades
            del cliente y un área de desarrollo encargada de atender las solicitudes y disposiciones para que el servicio brindado
            esté cada vez más a la altura de las necesidades y requerimientos del mercado.
            </h5>
          </Row> 
          
        </Container>
        <Container
          fluid
          style={{ backgroundColor: "#696969", top: 0, paddingTop: "100px" }}
        >
          <div>
            <h3 className="text-center" style={{color: "#FFFFFF", top: 0 }} >
              Acerca de nosotros
            </h3>
          </div>
          <Row style = {{marginTop:"40px"}}>
            <h5 className="text-center" style={{color: "#FFFFFF", top: 0, paddingBottom: "100px" }} >
            PowerFrames no sólo ha llevado a las salas de control la información en tiempo real de los indicadores basados en el riesgo 
            al que están sometidos los activos por la influencia de fenómenos del clima, sino también a diferentes sectores de la industria. 
            La plataforma ofrece a sus usuarios herramientas que elevan la consciencia situacional en la operación de los sistemas, lo que 
            permite tomar decisiones sobre la gestión para mitigar los riesgos por descargas atosféricas en los diferentes escenarios. 
            Específicamente con los planes de operación debido a causales como el fallo en un activo critico debido al impacto de un rayo, 
            fuertes vientos o lluvias torrenciales. También permite emitir alertas tempranas automáticas y generar reportes para el análisis 
            post-operativo de eventos de fallo.
            </h5>
          </Row> 
          
        </Container>


        <Container
          fluid
          style={{ backgroundColor: "#FFFFFF", top: 0, paddingTop: "100px" }}
        >
          <div>
            <h3 className="text-center">
              CLIENTES
            </h3>
          </div>
          <Row style = {{marginTop:"5px", marginBottom:"40px"}}>
            <img alt="" style={{ width: "150px" }} src="isa.jpg" />
          </Row>
          <div>
            <h3 className="text-center">
              PROVEEDORES
            </h3>
          </div>
          <Row style = {{marginTop:"40px",paddingBottom: "100px" }}>
            <Col/>
            <Col/>   
            <Col className="keys">
              <img alt="" style={{ width: "180px",marginTop:"30px" }} src="aws.png" />
            </Col>
            <Col className="keys">
              <img alt="" style={{ width: "150px" }} src="nowcast.png" />
            </Col>
            <Col/> 
            <Col/> 
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Company);
