import { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCubes,faLifeRing,faBook } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons'
import './landingPage.css'
import { connect } from "react-redux";
import { withTranslation} from "react-i18next";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../footer/footer.component'
import Button from 'react-bootstrap/Button'
import { NavBar as PortalNavBar } from '../modules/navBar/navBar.component'
import { NavBar } from '../navBar/navBar.component'

class LandingPage extends Component<any,any> {
    constructor(props: any) {
        super(props);
        const { i18n } = this.props;
        i18n.changeLanguage('es');
    }
    render() {
        const { t } = this.props;
        const user = this.props.state.authentication.user;
        return (
            <div >
                {user ? <PortalNavBar/> : <NavBar/>}
                <div className="first-section-landing" style={{background: `url("${window.location.origin}/banner.png") no-repeat`}} id={"home"}>
                
                    <div className="content">
                        <div className="logo">
                            <img className="image text-left" alt="powerframes text" src="g1140.png"/>
                        </div>
                        <br/>
                        <br/>
            
                        <div className="btn-conozca row">
                            <div className="col-12 text-left">
                                <Button as={HashLink} to={"/#about"} className="btn-conozca btn btn-info">{t('more')}</Button>
                            </div>
                        </div>
                        <div className="div-social">
                            <a href="https://www.linkedin.com/company/power-frames/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="social-icon" icon={faLinkedinIn}/>
                            </a>
                            <a href="https://www.instagram.com/powerframes.co/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="social-icon" icon={faInstagram}/>
                            </a>
                            <a href="https://twitter.com/powerframesco" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="social-icon" icon={faTwitter}/>
                            </a>
                        </div>
                    </div>
                </div>
               
                <Container fluid className="key-container" id = {"about"}>
                  
                    <div className="header-landing" >
                        <h3 id="abouttitle" className="text-center">{t('abouttitle')}</h3>
                        <p id="aboutdesc" className="text-center text-large">{t('aboutdesc')}</p>
                    </div>
                    <Row>
                        <Col className="keys">
                        <FontAwesomeIcon className="fa-4x" icon={faBook}  style={{color:"#01aad5ff"}}/>
                            <p id="expert" className="w3-large">{t('expert')}</p>
                            <p id="expertdesc">{t('expertdesc')}</p>
                        </Col>
                        <Col className="keys">
                            <img className="" alt="cluster" width="64px" src="cluster.png"/>
                            <p id="availability" className="w3-large">{t('availability')}</p>
                            <p id="availabilitydesc">{t('availabilitydesc')}</p>
                        </Col>
                        <Col className="keys">
                            <FontAwesomeIcon className="fa-4x" icon={faCubes}  style={{color:"#f7e27aff"}}/>
                            <p id="scalability" className="w3-large">{t('scalability')}</p>
                            <p id="scalabilitydesc">{t('scalabilitydesc')}</p>
                        </Col>
                        <Col className="keys">
                            <img className="" alt="security" width="64px" src="cs.png"/>
                            <p id="security" className="w3-large">{t('security')}</p>
                            <p id="securitydesc">{t('securitydesc')}</p>
                        </Col>
                        <Col className="keys">
                            <FontAwesomeIcon className="fa-4x" icon={faLifeRing}  style={{color:"#1e8ba1ff"}}/>
                            <p id="support" className="w3-large">{t('support')}</p>
                            <p id="supportdesc">{t('supportdesc')}</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid style ={{backgroundColor: '#575957', paddingTop: 50 ,paddingBottom: 50 }}>                      
                    <Row  className="video_wrapper">
                        <iframe title="videoPF" id="video" className="videoiframe" src="https://www.youtube.com/embed/T1F-_E33Nxc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowFullScreen></iframe>
                    </Row>                   
                </Container>

                
                <Footer/>

            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedLandingPage = withTranslation()(connect(mapStateToProps)(LandingPage));

export { connectedLandingPage as LandingPage };
