import { Dispatch } from "redux";
import { reportsService } from '../_services';
import Swal from 'sweetalert2'
import { history } from '../_helpers';
import { userService } from '../_services'

export const reportsActions = {
    reportEvent,
    substationEvent,
    pointEvent,
    historicEvent
};

function reportEvent(data: any) {
    return (dispatch: Dispatch) => {
        dispatch(request(data.circuit))

        reportsService.reportEvent(data)
            .then(
                (res: any) => {
                    Swal.fire({
                        title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                        text: "En breve, los destinatarios recibiran un correo",
                        icon: 'success',
                    }).then(() => {
                        history.push('/home');
                    })
                    dispatch(success(res));
                },
                (error: any) => {
                    console.log(error.response.data.error.statusCode)
                    if (error.response.data.error.statusCode === 401) {
                        userService.refreshToken().then(() => {
                            reportsService.reportEvent(data)
                                .then(
                                    (res: any) => {
                                        Swal.fire({
                                            title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                                            text: "En breve, los destinatarios recibiran un correo",
                                            icon: 'success',
                                        }).then(() => {
                                            history.push('/home');
                                        })
                                        dispatch(success(res));
                                    },
                                    (error: any) => {
                                        Swal.fire({
                                            title: "La sesión ha expirado.",
                                            text: "Por favor, vuelva a ingresar.",
                                            icon: 'warning',
                                        }).then(() => {
                                            userService.logout();
                                            history.push('/login');
                                        })
                                        dispatch(failure(error));
                                    })
                        },
                        (error: any) => {
                            Swal.fire({
                                title: "La sesión ha expirado.",
                                text: "Por favor, vuelva a ingresar.",
                                icon: 'warning',
                            }).then(() => {
                                userService.logout();
                                history.push('/login');
                            })
                            dispatch(failure(error));
                        });
                    } else {
                        Swal.fire({
                            title: "Ha ocurrido un error.",
                            text: "Por favor, contacte a soporte.",
                            icon: 'warning',
                        })
                        dispatch(failure(error));
                    }
                }
            );
    }
    function request(user: any) { return { type: "REPORT_REQUEST", user } }
    function success(user: any) { return { type: "REPORT_SUCCESS", user } }
    function failure(error: any) { return { type: "REPORT_FAILED", error } }
}

function substationEvent(data: any) {
    return (dispatch: Dispatch) => {
        dispatch(request(data.circuit))

        reportsService.substationEvent(data)
            .then(
                (res: any) => {
                    Swal.fire({
                        title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                        text: "En breve, los destinatarios recibiran un correo",
                        icon: 'success',
                    }).then(() => {
                        history.push('/home');
                    })
                    dispatch(success(res));
                },
                (error: any) => {
                    console.log(error.response.data.error.statusCode)
                    if (error.response.data.error.statusCode === 401) {
                        userService.refreshToken().then(() => {
                            reportsService.substationEvent(data)
                                .then(
                                    (res: any) => {
                                        Swal.fire({
                                            title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                                            text: "En breve, los destinatarios recibiran un correo",
                                            icon: 'success',
                                        }).then(() => {
                                            history.push('/home');
                                        })
                                        dispatch(success(res));
                                    },
                                    (error: any) => {
                                        Swal.fire({
                                            title: "La sesión ha expirado.",
                                            text: "Por favor, vuelva a ingresar.",
                                            icon: 'warning',
                                        }).then(() => {
                                            userService.logout();
                                            history.push('/login');
                                        })
                                        dispatch(failure(error));
                                    })
                        },
                            (error: any) => {
                                Swal.fire({
                                    title: "La sesión ha expirado.",
                                    text: "Por favor, vuelva a ingresar.",
                                    icon: 'warning',
                                }).then(() => {
                                    userService.logout();
                                    history.push('/login');
                                })
                                dispatch(failure(error));
                            });
                    } else {
                        Swal.fire({
                            title: "Ha ocurrido un error.",
                            text: "Por favor, contacte a soporte.",
                            icon: 'warning',
                        })
                        dispatch(failure(error));
                    }
                }
            );
    }

    function request(user: any) { return { type: "REPORT_REQUEST", user } }
    function success(user: any) { return { type: "REPORT_SUCCESS", user } }
    function failure(error: any) { return { type: "REPORT_FAILED", error } }
}

function pointEvent(data: any) {
    return (dispatch: Dispatch) => {
        dispatch(request(data.circuit))

        reportsService.pointEvent(data)
            .then(
                (res: any) => {
                    Swal.fire({
                        title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                        text: "En breve, los destinatarios recibiran un correo",
                        icon: 'success',
                    }).then(() => {
                        history.push('/home');
                    })
                    dispatch(success(res));
                },
                (error: any) => {
                    console.log(error.response.data.error.statusCode)
                    if (error.response.data.error.statusCode === 401) {
                        userService.refreshToken().then(() => {
                            reportsService.pointEvent(data)
                                .then(
                                    (res: any) => {
                                        Swal.fire({
                                            title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                                            text: "En breve, los destinatarios recibiran un correo",
                                            icon: 'success',
                                        }).then(() => {
                                            history.push('/home');
                                        })
                                        dispatch(success(res));
                                    },
                                    (error: any) => {
                                        Swal.fire({
                                            title: "La sesión ha expirado.",
                                            text: "Por favor, vuelva a ingresar.",
                                            icon: 'warning',
                                        }).then(() => {
                                            userService.logout();
                                            history.push('/login');
                                        })
                                        dispatch(failure(error));
                                    })
                        },
                            (error: any) => {
                                Swal.fire({
                                    title: "La sesión ha expirado.",
                                    text: "Por favor, vuelva a ingresar.",
                                    icon: 'warning',
                                }).then(() => {
                                    userService.logout();
                                    history.push('/login');
                                })
                                dispatch(failure(error));
                            });
                    } else {
                        Swal.fire({
                            title: "Ha ocurrido un error.",
                            text: "Por favor, contacte a soporte.",
                            icon: 'warning',
                        })
                        dispatch(failure(error));
                    }
                }
            );
    }

    function request(user: any) { return { type: "REPORT_REQUEST", user } }
    function success(user: any) { return { type: "REPORT_SUCCESS", user } }
    function failure(error: any) { return { type: "REPORT_FAILED", error } }
}

function historicEvent(data: any) {
    return (dispatch: Dispatch) => {
        dispatch(request(data.circuit))

        reportsService.historicEvent(data)
            .then(
                (res: any) => {
                    Swal.fire({
                        title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                        text: "En breve, los destinatarios recibiran un correo",
                        icon: 'success',
                    }).then(() => {
                        history.push('/home');
                    })
                    dispatch(success(res));
                },
                (error: any) => {
                    console.log(error.response.data.error.statusCode)
                    if (error.response.data.error.statusCode === 401) {
                        userService.refreshToken().then(() => {
                            reportsService.historicEvent(data)
                                .then(
                                    (res: any) => {
                                        Swal.fire({
                                            title: `El reporte ${res.data.request_id} fue solicitado con exito`,
                                            text: "En breve, los destinatarios recibiran un correo",
                                            icon: 'success',
                                        }).then(() => {
                                            history.push('/home');
                                        })
                                        dispatch(success(res));
                                    },
                                    (error: any) => {
                                        Swal.fire({
                                            title: "La sesión ha expirado.",
                                            text: "Por favor, vuelva a ingresar.",
                                            icon: 'warning',
                                        }).then(() => {
                                            userService.logout();
                                            history.push('/login');
                                        })
                                        dispatch(failure(error));
                                    })
                        },
                        (error: any) => {
                            Swal.fire({
                                title: "La sesión ha expirado.",
                                text: "Por favor, vuelva a ingresar.",
                                icon: 'warning',
                            }).then(() => {
                                userService.logout();
                                history.push('/login');
                            })
                            dispatch(failure(error));
                        });
                    } else {
                        Swal.fire({
                            title: "Ha ocurrido un error.",
                            text: "Por favor, contacte a soporte.",
                            icon: 'warning',
                        })
                        dispatch(failure(error));
                    }
                }
            );
    }
    function request(user: any) { return { type: "REPORT_REQUEST", user } }
    function success(user: any) { return { type: "REPORT_SUCCESS", user } }
    function failure(error: any) { return { type: "REPORT_FAILED", error } }
}