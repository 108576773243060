import React, { Component } from "react";
import { connect } from 'react-redux';

import { Redirect, withRouter, Link } from "react-router-dom";
import { Dropdown, Navbar, Nav, Modal, Button } from "react-bootstrap";
import { faLifeRing, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userActions } from "../../../_actions";
import './navBar.component.css'

class NavBar extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "Tiempo Real",
            showSupport: false,
            isOpen: false,
            isMobile: true,
            previousWidth: -1,
        }
        this.handleLogout = this.handleLogout.bind(this);
        this.updateWidth = this.updateWidth.bind(this);
    }
    /**
     * Add event listener
    */
    componentDidMount() {
        this.updateWidth();
        window.addEventListener("resize", this.updateWidth.bind(this));
    }

    /**
         * Remove event listener
        */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth.bind(this));
    }

    updateWidth() {
        const width = window.innerWidth;
        const widthLimit = 576;
        const isMobile = width <= widthLimit;
        const wasMobile = this.state.previousWidth <= widthLimit;

        if (isMobile !== wasMobile) {
            this.setState({
                isOpen: !isMobile
            });
        }

        this.setState({ previousWidth: width });
    }



    handleLogout() {
        const { dispatch } = this.props;
        dispatch(userActions.logout())
        return false;
    }
    render() {
        if (!this.props.state.authentication.user) {
            return (<Redirect to={'/'} />)

        } else {
            return (
                <>
                    <Navbar bg="dark" variant="dark" className="sticky-navbar-modules d-flex justify-content-between">
                        <div className="d-flex">
                            <Navbar.Brand as={Link} to={"/home"} style={{ height: "40px" }}><img className="barlogo" alt="Logo" src="logo2.png"
                                height="20px" /></Navbar.Brand>
                            <Nav>
                                {
                                    this.props.toggle ? <Nav.Item>
                                        <Button variant="outline-info" size="sm" onClick={this.props.toggle}><FontAwesomeIcon icon={faAlignLeft} /></Button>
                                    </Nav.Item>
                                        :
                                        null
                                }
                                { /*
                                    <p className="filter-text" style={{ color: "white" }}>{this.state.title}</p>
                                    <Nav.Item>
                                        <BootstrapSwitchButton
                                            checked={this.state.showMap}
                                            onlabel='Mapa'
                                            offlabel='Datos'
                                            width={80}
                                            size="xs"
                                            height={30}
                                            onChange={(checked: boolean) => {
                                                if (checked)
                                                    this.setState({ showMap: true });
                                                else {
                                                    this.setState({ showMap: false });
                                                    this.setState({
                                                        frame2: false,
                                                        frame3: false,
                                                        frame4: false,
                                                    })
                                                }
                                            }}
                                        />
                                    </Nav.Item>
                                    <Nav.Item>
                                    <button className={"btn btn-info"} onClick={() => this.setState({ dfShow: true, showMap: this.state.showMap })}>Filtro por fecha </button>
                                </Nav.Item>
                                <Nav.Item>
                                    <button className={"btn btn-info"} onClick={() => this.setState({ rfShow: true })}>Filtro relativo</button>
                                </Nav.Item>
                                    */
                                }
                            </Nav>
                        </div>

                        <p className="filter-text">{this.props.title}</p>
                        <div>
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Button className="lifeRing" variant="link" onClick={() => this.setState({showSupport: true})}><FontAwesomeIcon icon={faLifeRing} size="lg" /></Button>
                                    <Dropdown>
                                        <Dropdown.Menu className={"dropdown-menu-user"} align="right">
                                            <Dropdown.Item className={"dropdown-item-user"}><button onClick={this.handleLogout} className="btn btn-danger">Cerrar sesión</button></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav.Item>
                                <Nav.Item>
                                    <Dropdown >
                                        <Dropdown.Toggle className="userCircle" variant="link" size="sm" id="dropdown-basic"><FontAwesomeIcon icon={faUserCircle} size="lg" /></Dropdown.Toggle>
                                        <Dropdown.Menu className={"dropdown-menu-user"} align="right">
                                            <Dropdown.Item className={"dropdown-item-user"}><button onClick={this.handleLogout} className="btn btn-danger">Cerrar sesión</button></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Navbar>
                    <Modal
                        show={this.state.showSupport}
                        onHide={() => this.setState({ showSupport: false })}
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Soporte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="text-center">
                                <a href="https://github.com/powerframes/manuales/blob/main/Plataforma_PowerFrames/README.md">Manual de usuario plataforma</a><br/><br/>
                                Para recibir soporte personalizado por favor escribir a <a href="mailto:support@powerframes.co">support@powerframes.co</a>
                            </p>
                        </Modal.Body>
                    </Modal>
                </>
            )
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedNavBar = connect(mapStateToProps)(withRouter(NavBar));
export { connectedNavBar as NavBar };