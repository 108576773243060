import React, { Component } from "react";
import "./form.css";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as lineas from "./itco-lista-circuitos-20240219.json";
import { reportsActions } from "../../_actions";
import PauseButton from "./pauseButton";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class Line extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: props.state.authentication.user.username, //username
      agent: "ITCO", // SLUG WORK TO DO: El dato debe ser dinamico y la información debe provenir de la DB
      circuit: "",
      datetime: "",
      distance_a: "",
      distance_b: "",
      email_list: "",
      lineInfo: "",
      advertisement: "",
      timestampAdvertisement: "",
      invalidTimestamp: false,
      maxDistanceA: "1000",
      maxDistanceB: "1000",

      // showInfo: "corr"
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.corrForm = this.corrForm.bind(this);
    // this.pointForm = this.pointForm.bind(this);
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value: any = target.value;
    const name = target.name;
    console.log(value);
    this.setState({
      [name]: value,
    });
    if (name === "circuit") {
      if (value) {
        const total_length_km =
          +lineas.ITCO[value as keyof typeof lineas.ITCO].total_length_km;
        const monitored_length_km =
          +lineas.ITCO[value as keyof typeof lineas.ITCO].monitored_length_km;
        const total =
          Math.abs(total_length_km - monitored_length_km) > 1
            ? `Longitud real : ${total_length_km} km, longitud monitoreada ${monitored_length_km} km`
            : `Monitoreada en su totalidad : ${total_length_km} km`;
        this.setState({
          maxDistanceA: total_length_km,
          maxDistanceB: total_length_km,
          lineInfo: `La línea ${value} se encuentra monitoreada entre la torre ${
            lineas.ITCO[value as keyof typeof lineas.ITCO].init_Tower
          } y la torre ${
            lineas.ITCO[value as keyof typeof lineas.ITCO].last_Tower
          } (${total}).`,
          advertisement:
            "Si la consulta se realiza por fuera de estas torres, la plataforma generará un error, intente nuevamente con distancia a la subestación A y a la subestación B en 0.",
        });
      }
    } else if (name === "datetime") {
      let now = new Date();
      let timestamp = new Date(value);

      if (now.getTime() - timestamp.getTime() < 1000 * 60 * 5) {
        this.setState({
          timestampAdvertisement:
            "Consolidación de datos en proceso. Espere un momento e intente nuevamente.",
          invalidTimestamp: true,
        });
      } else {
        this.setState({ timestampAdvertisement: "", invalidTimestamp: false });
      }
    } else {
      this.setState({
        maxDistanceA: "1000",
        maxDistanceB: "1000",
        lineInfo: "",
        advertisement: "",
      });
    }
  }

  // corrForm() {
  //   this.setState({ ["showInfo"]: "corr" })
  // }
  // pointForm() {
  //   this.setState({ ["showInfo"]: "point" })
  // }
  async handleSubmit() {
    const { dispatch } = this.props;
    const {
      lineInfo,
      advertisement,
      timestampAdvertisiment,
      invalidTimestamp,
      maxDistanceA,
      maxDistanceB,
      ...data
    } = this.state;
    dispatch(reportsActions.reportEvent(data));
    await sleep(10000);
    console.log(this.state);
  }
  render() {
    return (
      <Container
        className="Form"
        fluid
        style={{ minHeight: "89.6vh", paddingTop: 40 }}
      >
        <Row>
          <h3>Formulario de solicitud de reportes de correlación</h3>
        </Row>
        <Row className="row pb-3 px-5 pt-5 d-flex flex-row">
          <h6>
            Llene por favor los campos presentados a continuación, para generar
            automaticamente un reporte de correlación que será enviado a su
            correo.
          </h6>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 pt-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-3">
            <label>Línea</label>
          </Col>
          <Col className="col-sm-9">
            <select
              id="circuit"
              className="form-control"
              name="circuit"
              value={this.state.circuit}
              onChange={this.handleInputChange}
              placeholder="Seleccione la línea"
            >
              <option></option>
              {Object.keys(lineas.ITCO).map((linea) => {
                return (
                  <option key={linea} value={linea}>
                    {linea}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row
          style={{ marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>
              {`${this.state.lineInfo} ${this.state.advertisement}`}
            </label>
          </Col>
        </Row>

        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-3">
            <label>Fecha del evento</label>
          </Col>
          <Col className="col-sm-4">
            <input
              type="datetime-local"
              min="2020-07-10"
              className="form-control"
              required
              id="datetime"
              name="datetime"
              step=".001"
              value={this.state.datetime}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        {this.state.invalidTimestamp ? (
          <Row
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            className="row pb-3 px-5"
          >
            <Col className="col-lg-12 col-sm-12">
              <label
                style={{ color: "#cc3300", fontWeight: "bold" }}
              >{`${this.state.timestampAdvertisement}`}</label>
            </Col>
          </Row>
        ) : null}

        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5 d-flex flex-row justify-content-start"
        >
          <Col className="col-sm-3">
            <label>Distancia a la Subestación A [km]</label>
          </Col>
          <Col className="col-sm-3">
            <input
              type="number"
              className="form-control"
              min="0"
              max={this.state.maxDistanceA}
              id="distance_a"
              name="distance_a"
              value={this.state.distance_a}
              onChange={this.handleInputChange}
            />
          </Col>
          <Col className="col-sm-3">
            <label>Distancia a la Subestación B [km]</label>
          </Col>
          <Col className="col-sm-3">
            <input
              type="number"
              className="form-control"
              min="0"
              max={this.state.maxDistanceB}
              id="distance_b"
              name="distance_b"
              value={this.state.distance_b}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 20, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>
              La subestación A es la primera del nombre de la linea.{" "}
              <em>
                Ej. en Cerromatoso - Porce III, la subestación A es Cerromatoso
                y la B es Porce III.
              </em>
            </label>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>
              Para realizar una correlación en toda la línea puede asignar las
              distancias a las subestaciones en cero (0).
            </label>
          </Col>
        </Row>
        <Row
          style={{ marginLeft: "1vw", marginRight: "1vw" }}
          className="row pb-3 px-5"
        >
          <Col className="col-lg-3 col-sm-12">
            <label>Correo electrónico</label>
          </Col>
          <Col className="col-lg-9 col-sm-12">
            <input
              required
              multiple
              className="form-control"
              type="email"
              name="email_list"
              id="email_list"
              value={this.state.email_list}
              onChange={this.handleInputChange}
              placeholder="Enviar reporte a correo electrónico"
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: 0, marginLeft: "1vw", marginRight: "1vw" }}
          className="row px-5"
        >
          <Col className="col-lg-12 col-sm-12">
            <label>Puede ingresar varios correos, separados por coma.</label>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} className="text-center pb-3">
          <PauseButton
            style={{ width: "200px" }}
            className="btn btn-lg btn-primary "
            type="submit"
            name="submit"
            disabled={
              !(
                !this.state.invalidTimestamp &&
                this.state.circuit &&
                this.state.datetime &&
                this.state.distance_a &&
                this.state.distance_b &&
                this.state.distance_a < this.state.maxDistanceA &&
                this.state.distance_b < this.state.maxDistanceB &&
                this.state.email_list
              )
            }
            onClick={this.handleSubmit}
          >
            Enviar solicitud
          </PauseButton>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    state,
  };
};

const connectedLine = connect(mapStateToProps)(Line);
export { connectedLine as Line };
