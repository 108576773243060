import React, { Component } from "react";
import "./products.css";
import { WithTranslation, withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../footer/footer.component";
import { NavBar } from "../navBar/navBar.component"

class Products extends Component<WithTranslation> {
  constructor(props: WithTranslation) {
    super(props);
    const { i18n } = this.props;
    i18n.changeLanguage("es");
  }
  render() {
    return (
      <div>
        <NavBar />
        <Container
          fluid
          style={{ backgroundColor: "#000000", top: 0, paddingTop: "100px" }}
        >
          <Row style = {{marginTop:"40px"}}>
          <Col> 
            <h3 className="text-center" style={{color: "#FFFFFF", top: 0 }} >
              AMALIA
            </h3>
            <img alt="" style={{ display:"block",marginLeft: "auto",marginRight: "auto", width: "30%" }} src="amalia.png" />
          </Col>

          <Col> 
            <h5 className="text-center" style={{color: "#FFFFFF", top: 0, paddingBottom: "100px", marginRight: "20px" }} >
            La plataforma de PowerFrames es una herramienta que tiene como propósito la supervisión, pronóstico y monitoreo de las 
            descargas atmosféricas y la vulnerabilidad de los activos pertenecientes al sistema eléctrico nacional.

            La plataforma está basada en un sistema llamado AMALIA (Advanced Monitoring of Atmospheric & Lightning Activity) que significa 
            monitoreo avanzado de actividad atmosférica y de rayos por sus siglas en inglés. AMALIA es una plataforma de monitoreo y 
            análisis en tiempo real de las líneas de operación para transmisión/distribución.
            </h5>
          </Col>
          </Row> 
          
        </Container>

        <Container fluid style={{ backgroundColor: "#FFFFFF", top: 0 }}>
          <Row
            style={{
              backgroundColor: "#FFFFFF",
              marginTop: 0,
              paddingTop: "100px",
            }}
          >
            <h3 id="architecture">ARQUITECTURA</h3>
          </Row>
          <Row>
            <img alt="" className="center" src="arch.png" />
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Products);
