import { Component, useState } from "react";
import "./navBar.css";
import { withTranslation, useTranslation } from "react-i18next";
import {
  Nav,
  Navbar,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { HashLink } from "react-router-hash-link";
import Row from "react-bootstrap/Row";
class NavBar extends Component<any, any> {
  constructor(props: any) {
    super(props);
    const { i18n } = this.props;
    i18n.changeLanguage("es");
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
    return false;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div>
        <Navbar
          bg="dark"
          variant="dark"
          className="navbar sticky-navbar"
          expand="md"
          sticky="top"
        >
          <Navbar.Brand as={Link} to={"/"}>
            <img className="barlogo" alt="Logo" src="logo2.png" height="20px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={HashLink} to={"/#about"}>
                {t("about")}
              </Nav.Link>
              <Nav.Link as={Link} to={"/company"}>
                {t("company")}
              </Nav.Link>
              <NavDropdown title="Productos" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to={"/products"}
                  className="d-flex flex-row justify-content-center"
                >
                  <img alt="" className="amalia-log" src="/amalia.png" />
                  <p className="product-text">AMALIA</p>
                </NavDropdown.Item>
              </NavDropdown>

              <Contact />
            </Nav>
            <Nav className="mr-sm-2">
              <Button size="sm" as={Link} to={"/login"} className="login-btn">
                {t("login")}
              </Button>
            </Nav>

            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id="button-tooltip-2">change language</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <Navbar.Text ref={ref}></Navbar.Text>
              )}
            </OverlayTrigger>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    state,
  };
}

const connectedNavBar = withTranslation()(
  connect(mapStateToProps)(withRouter(NavBar))
);

export { connectedNavBar as NavBar };

function Contact() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  return (
    <>
      <Nav.Link onClick={handleShow}>{t("contact")}</Nav.Link>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("contacttitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-between">
          {t("contactdesc")}
          <Row className="d-flex flex-row justify-content-center">
            <a href="mailto:info@powerframes.co">info@powerframes.co</a>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
