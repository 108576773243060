declare global {
    interface Window {
        REACT_APP_BACKEND_URL:string,
        REACT_APP_CLIENT_ID: string,
        REACT_APP_CLIENT_SECRET: string
    }
}
export const config = {
    backend_url: window.REACT_APP_BACKEND_URL,
    client_id: window.REACT_APP_CLIENT_ID,
    client_secret: window.REACT_APP_CLIENT_SECRET
}
