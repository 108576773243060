import {
  faHistory
} from "@fortawesome/free-solid-svg-icons";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import "./sideBar.component.css"
import SubMenu from './subMenu/subMenu.component';
type myProps = {
  toggle: any,
  handleFunctions: ((event: any) => any)[],
  showReset: Boolean,
  isOpen: Boolean
}
function SideBar(props: myProps) {
  return (
    <div className={classNames("sidebar", { "is-open": props.isOpen })}>

      <Nav className="flex-column sidebarNav">

        <SubMenu
          key="amalia"
          title="AMALIA"
          items={["Mapa principal", "Datos", "Reanálisis", "Calidad de datos"]}
          action={props.handleFunctions[0]}
        />
        {props.showReset ?
          <SubMenu
            key="filter"
            title="Filtro temporal"
            items={["Filtro relativo", "Filtro absoluto", "Limpiar filtros"]}
            icon={faHistory}
            action={props.handleFunctions[1]}
          /> :
          <SubMenu
            key="filter"
            title="Filtro temporal"
            items={["Filtro relativo", "Filtro absoluto"]}
            icon={faHistory}
            action={props.handleFunctions[1]}
          />
        }
      </Nav>
    </div>
  );
}

export { SideBar };