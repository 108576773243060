import {Dispatch} from "redux";
import { kibanaService } from '../_services';


export const kibanaActions = {
    login
};

function login(username: string, password: string) {
    return (dispatch:Dispatch)  => {
        dispatch(request(username))

        kibanaService.login(username, password)
            .then(
                (user:any) => {
                    dispatch(success(user));
                },
                (error:any) => {
                    dispatch(failure(error));
                }
            );
    }
    function request(user:any) { return { type: "KIBANA_LOGIN_REQUEST", user } }
    function success(user:any) { return { type: "KIBANA_LOGIN_SUCCESS", user } }
    function failure(error:any) { return { type: "KIBANA_LOGIN_FAILED", error } }
}