
export function kibana(state = {}, action:any) {
  switch (action.type) {
    case "KIBANA_LOGIN_SUCCESS":
        return {
            login: true
        }
    default:
      return state
  }
}
