import React, { useState } from "react";

export default function PauseButton(props: any){
    const [exec, setExec] = useState(false);

    const {disabled, onClick, ...other } = props

    
    return(
        <button onClick={async e=>
            {setExec(true);
            try {
                await onClick()
            
            } finally{
                setExec(false);
            }
        }}
        disabled={exec|| disabled}
        {...other}
        />


    );
}