import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import {Dispatch} from "redux";
import Swal from 'sweetalert2'

export const userActions = {
    login,
    logout,
    getAll,
    refreshSession
};

function login(username: string, password: string) {
    return (dispatch:Dispatch)  => {
        dispatch(request({ username: "dummy" }));

        userService.login(username, password)
            .then(
                (user:any) => {
                    dispatch(success(user));
                    history.push('/home');
                },
                (error:any) => {
                    Swal.fire({
                        title: 'Credenciales incorrectas',
                        text: "Por favor, verifica tus credenciales",
                        icon: 'warning',
                      })
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user:any) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user:any) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error:any) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return (dispatch:Dispatch) => {
        dispatch(request());

        userService.getAll()
            .then(
                (users:any) => dispatch(success(users)),
                (error:any) => {
                    dispatch(failure(error))
                    userService.logout();
                    return { type: userConstants.LOGOUT };
                }
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users:any) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error:any) { return { type: userConstants.GETALL_FAILURE, error } }
}

function refreshSession() {
    return userService.refreshToken()
            .then(
                () => {return true},
                (error:any) => {
                    userService.logout();
                    return { type: userConstants.LOGOUT };
                }
            );

    // function request() { return { type: userConstants.GETALL_REQUEST } }
    // function success(users:any) { return { type: userConstants.GETALL_SUCCESS, users } }
    // function failure(error:any) { return { type: userConstants.GETALL_FAILURE, error } }
}
