import { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { kibanaActions, grafanaActions } from "../../../_actions";
import './predictions.component.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

import { NavBar } from "../navBar/navBar.component";
import Footer from "../footer/footer.component"

class AtmosphericPredictions extends Component<any, any> {
    constructor(props: any) {
        super(props);
        const { dispatch } = this.props;
        dispatch(kibanaActions.login("intercolombia", "eeZEjjvfuwm89RR"));
        dispatch(grafanaActions.login("intercolombia", "eeZEjjvfuwm89RR"));
        this.state = {
            mainFrameSrc: "https://kibana.powerframes.co/app/dashboards#/view/03697930-a241-11eb-b0b7-27e1d81477ba?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2B1h%2Cto%3Anow%2B2h))&hide-filter-bar=true",
            bottomFrameSrc: "https://grafana.powerframes.co/d-solo/m-X4qXXGz/lpi?orgId=1&from=now&to=now%2B24h&theme=dark&panelId=2",
            hoursAhead: 1,
            play: false,
            title: `1 periodo adelante: de ${new Date().getHours()}:00 a ${this.calculateHour(1).getHours()}:00`,
        }
        this.play = this.play.bind(this);
        this.toogleBottomIframe = this.toogleBottomIframe.bind(this);
    }

    calculateHour = (h: number) => {
        let a = new Date();
        a.setTime(a.getTime() + (h * 60 * 60 * 1000));
        return a;
    }

    nestCall = () => {
        setTimeout(() => {   //  call a 3s setTimeout when the loop is called
            if (this.state.hoursAhead < 24 && this.state.play) {
                this.setState({
                    mainFrameSrc: `https://kibana.powerframes.co/app/dashboards#/view/03697930-a241-11eb-b0b7-27e1d81477ba?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2B${this.state.hoursAhead}h%2Cto%3Anow%2B${this.state.hoursAhead + 1}h))&hide-filter-bar=true`,
                    hoursAhead: this.state.hoursAhead + 1,  //  increment the counter
                    title: `${this.state.hoursAhead + 1} periodos adelante: de ${this.calculateHour(this.state.hoursAhead).getHours()}:00 a ${this.calculateHour(this.state.hoursAhead + 1).getHours()}:00`
                })         //  if the counter < 10, call the loop function
                this.nestCall();             //  ..  again which will trigger another 
            } else if (this.state.hoursAhead > 23) {
                this.setState({
                    play: false,
                    mainFrameSrc: "https://kibana.powerframes.co/app/dashboards#/view/03697930-a241-11eb-b0b7-27e1d81477ba?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2B1h%2Cto%3Anow%2B2h))&hide-filter-bar=true",
                    hoursAhead: 1,  //  increment the counter
                    title: "1 hora adelante"
                });
            } else {
                this.setState({
                    play: false
                });
            }
        }, 2000)
    }

    play = () => {
        this.setState({
            play: true
        })
        this.nestCall();

    }

    toogleBottomIframe = (e: any) => {
        switch (parseInt(e.target.id)) {
            case 1:
                this.setState({
                    bottomFrameSrc: "https://grafana.powerframes.co/d-solo/m-X4qXXGz/lpi?orgId=1&from=now&to=now%2B24h&theme=dark&refresh=30m&panelId=2",
                });
                break;
            case 2:
                this.setState({
                    bottomFrameSrc: "https://grafana.powerframes.co/d-solo/iuvVnv3Mz/riesgo-en-linea?orgId=1&from=now&to=now%2B24h&refresh=30m&theme=dark&panelId=2",
                });
                break;
            case 3:
                this.setState({
                    bottomFrameSrc: "https://kibana.powerframes.co/app/visualize#/edit/06c273d0-b9dd-11eb-ae28-492f4eab7b07?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2Cto%3Anow%2B3h))",
                });
                break;
            case 4:
                this.setState({
                    bottomFrameSrc: "https://kibana.powerframes.co/app/visualize#/edit/ccf74440-b9dd-11eb-ae28-492f4eab7b07?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2Cto%3Anow%2B3h))",
                });
                break;

        }
    }

    stop = () => {
        this.setState({
            play: false
        })
    }

    render() {
        if (!this.props.state.authentication.user) {
            return (<Redirect to={'/login'} />)
        } else {
            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Power Frames &gt; Dashboard</title>
                    </Helmet>
                    <NavBar title={this.state.title} />
                    <div className="wrapper">
                        <div>
                            <div className={"filter-box"}>
                                <div className={"d-flex flex-column justify-content-center"} style={{ width: "70%" }}>
                                    <div className={"d-flex flex-xl-row justify-content-between"}>{
                                        !this.state.play ?
                                            <button className={"btn btn-primary btn-sm btn-circle "} onClick={this.play}><FontAwesomeIcon icon={faPlay} size="sm" /></button> :
                                            <button className={"btn btn-primary btn-sm btn-circle "} onClick={this.stop}><FontAwesomeIcon icon={faPause} size="sm" /></button>
                                    }
                                        <input id={"range"} type="range" min="1" max="23" value={this.state.hoursAhead} onChange={(event) => { this.setState({ title: `${parseInt(event.target.value)} periodos adelante: de ${this.calculateHour(parseInt(event.target.value)).getHours()}:00 a ${this.calculateHour(parseInt(event.target.value) + 1).getHours()}:00`, hoursAhead: parseInt(event.target.value), mainFrameSrc: `https://kibana.powerframes.co/app/dashboards#/view/03697930-a241-11eb-b0b7-27e1d81477ba?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!f%2Cvalue%3A900000)%2Ctime%3A(from%3Anow%2B${parseInt(event.target.value)}h%2Cto%3Anow%2B${parseInt(event.target.value) + 1}h))&hide-filter-bar=true` }); }} />
                                    </div>
                                </div>

                            </div>
                            <div className={"pred-frame1"}>
                                <iframe className={"pred-frame1"} title="map" id="frame1" src={this.props.state.kibana.login ? this.state.mainFrameSrc : ""} />
                            </div>
                            <div className={"pred-frame2"}>
                                <div className="d-flex flex-column justify-content-around">
                                    <button className="bg-dark text-white bg-bar rounded border-secondary btn" id="1" onClick={this.toogleBottomIframe}>LPI</button>
                                    <button className="bg-dark text-white bg-bar rounded border-secondary btn" id="2" onClick={this.toogleBottomIframe}>REL</button>
                                    <button className="bg-dark text-white bg-bar rounded border-secondary btn" id="3" onClick={this.toogleBottomIframe}>CC</button>
                                    <button className="bg-dark text-white bg-bar rounded border-secondary btn" id="4" onClick={this.toogleBottomIframe}>RPT</button>

                                </div>
                                <iframe className={"frame"} title="map" id="frame2" src={this.props.state.kibana.login ? this.state.bottomFrameSrc : ""} />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        state
    };
}

const connectedAtmosphericPredictions = connect(mapStateToProps)(withRouter(AtmosphericPredictions));
export { connectedAtmosphericPredictions as AtmosphericPredictions };
