import axios from "axios";

export const kibanaService = {
    login
};


function login(username: string, password: string) {
    //let payload = Buffer.from(`${username}:${password}`).toString('base64');
    const data = {
            "providerType": "basic",
            "providerName": "basic",
            "currentURL": "https://kibana.powerframes.co/",
            "params": {
                "username": username,
                "password": password
            }
    }

    const headers = {
        "kbn-xsrf": true
    }

    return axios.post("https://kibana.powerframes.co/internal/security/login",data, {headers: headers,withCredentials: true}).then(()=> {return true}).catch((err) => {return err})
}