import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Component } from "react";
import { userActions } from '../../_actions';
import { Dispatch } from "redux";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import './home.component.css';
import { NavBar } from '../modules/navBar/navBar.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface User {
    id: number,
    firstName: string,
    lastName: string
}

interface myProps {
    dispatch: Dispatch,
    user: User,
    users: {
        loading: string,
        error: string,
        items: any
    }
}

interface myState {
    users: any,
    authentication: any,
    predictions: boolean
}
class Home extends Component<myProps, myState> {
    constructor(props: myProps) {
        super(props)
        this.state = {
            users: props.users,
            authentication: props.user,
            predictions: true
        }
    }
    componentDidMount() {
        this.props.dispatch<any>(userActions.getAll());
    }

    render() {
        if (!this.props.users) {
            return (<Redirect to={'/login'} />)
        } else {
            let { user } = this.props;
            return (
                <div>
                    <NavBar />

                    <div className="first-section" style={{ backgroundImage: `url(${window.location.origin + '/background.gif'}` }}>
                    <h1 style={{ color: "white" }}>Hola {user?.firstName}!</h1>
                        <h1 style={{ color: "white" }}>Portal Framework</h1>
                    </div>
                    { this.state.predictions ?
                    null : <div className="stickyback" onClick={() => this.setState({ predictions: true })}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                    </div>}
                    
                    <div className="second-section">
                        <div className="modules-header">
                            <div className="row">
                            { this.state.predictions ? <h1>Módulos AMALIA</h1> : <h1>AMALIA Predicciones</h1>}
                            </div>
                        </div>
                        {this.state.predictions ?
                            <div className="modules">
                                <div className="obj clickable" onClick={() => this.setState({ predictions: false })}>

                                    <img className="inImg" alt="predicciones" src="prediction.svg" />
                                    <h3>Predicciones</h3>
                                </div>
                                <div className="obj">
                                    <Link to={"/dashboard"}>
                                        <img className="inImg" alt="dashboard" src="dashboard.svg" />
                                        <h3>Supervisión</h3>
                                    </Link>
                                </div>
                                <div className="obj">
                                    <Link to={"/report"}>
                                        <img className="inImg" alt="request" src="report.svg" />
                                        <h3>Reportes</h3>
                                    </Link>

                                </div>
                            </div>
                            :
                            <div className="modules">

                                <div className="obj">
                                    <Link to={"/atmosphericPredict"}>
                                        <img className="inImg" alt="electric failure" src="prediction.svg" />
                                        <h3>Riesgo por Descarga Atmosférica</h3>
                                    </Link>
                                </div>
                                <div className="obj">
                                    <Link to={"/massPredict"}>
                                        <img className="inImg" alt="electric failure" src="prediction.svg" />
                                        <h3>Riesgo por Movimientos en Masa</h3>
                                    </Link>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: myState) => {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedHome = connect(mapStateToProps)(Home);
export { connectedHome as Home };
