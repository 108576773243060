import React from "react";
import { Accordion, Nav, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import './subMenu.component.css'

type myProps = {
  icon?: any,
  items: string[],
  title: string,
  action: ((event: any) => any)
}

class SubMenu extends React.Component<myProps, any> {
  constructor(props: myProps) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const { icon, items, title,action } = this.props;
    return (
      <Nav.Item className={"sideBar-item" + classNames({ open: !this.state.collapsed })}>
        <Accordion>
          <Accordion.Toggle
            as={Nav.Link}
            variant="link"
            eventKey="0"
            onClick={this.toggleNavbar}
          >
            <div className="d-flex flex-column justify-content-center">
              <div>
                {
                  icon ?
                    <FontAwesomeIcon
                      icon={icon}
                      className="mr-2"
                    /> :
                    <img alt="" className="amalia-log" src="/amalia.png" />
                }
                <span style={{verticalAlign: "middle"}}>
                {title}
                </span>
                <FontAwesomeIcon
                  icon={this.state.collapsed ? faCaretDown : faCaretUp}
                  className="float-right"
                />

              </div>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse as={Card.Header} eventKey="0">
            <nav className="nav flex-column ml-4">
              {
                items.map((item: any,index: any) => {
                  
                  return <Button key={index} style={{width: "80%"}} variant="link" id={index} className="menu-links" onClick={() => {action(index)}}>{item}</Button>
                })
              }
            </nav>
          </Accordion.Collapse>
        </Accordion>
      </Nav.Item>
    );

    // return (
    //   <div>
    //     <NavItem
    //       onClick={this.toggleNavbar}
    //       className={classNames({ "menu-open": !this.state.collapsed })}
    //     >
    //       <NavLink className="dropdown-toggle" href="#">
    //         <FontAwesomeIcon icon={icon} className="mr-2" />
    //         {title}
    //       </NavLink>
    //     </NavItem>
    //     <Collapse
    //       isOpen={!this.state.collapsed}
    //       navbar
    //       className={classNames("items-menu", {
    //         "mb-1": !this.state.collapsed
    //       })}
    //     >
    //       {items.map(item => (
    //         <NavItem key={item} className="pl-4">
    //           <NavLink>{item}</NavLink>
    //         </NavItem>
    //       ))}
    //     </Collapse>
    //   </div>
    // );
  }
}

export default SubMenu;